'use strict';

(function(angular) {

    var module = angular.module('elmo.service.ratingFactory', ['elmo.factory.debounce']);

    function Rating(ratingObject, debounce) {
        var self = this;

        angular.extend(self, ratingObject);

        // add debounce to onChange callback
        self.onChange = self.onChange ? debounce(self.onChange, 1000, false) : null;

        self.isRatingActive = function(rating) {
            return self.activeRating && self.activeRating.id === rating.id;
        };

        self.setActiveRating = function(rating) {
            angular.extend(self.activeRating, rating);

            if (self.onChange) {
                self.onChange();
            }
        };

        self.onRatingValueChange = function(ratingValue) {
            var ratingItem = _.find(self.ratingSystem, {value: ratingValue});

            if (ratingItem) {
                self.setActiveRating(ratingItem);
            }
        };

        return self;
    }

    ratingFactory.$inject = ['debounce']

    function ratingFactory(debounce) {
        return function (ratingObject) {
            return new Rating(ratingObject, debounce);
        }
    }

    module.factory('ratingFactory', ratingFactory);

})(angular)
