'use strict';

(function(angular) {

    /**
     * @desc Config class for InputEntityChoice directive
     */
    angular
        .module('elmo.directives.input-entity-choice.configFactory', [])
        .factory('InputEntityChoiceConfigFactory', InputEntityChoiceConfigFactory);

    /**
     * Configuration class for the InputLabel field
     * @typedef InputEntityChoiceConfigFactory
     */
    function InputEntityChoiceConfigFactory()
    {
        /**
         * @typedef InputEntityChoiceConfig
         * @constructor
         */
        function InputEntityChoiceConfig() {

            /**
             * Token to pass to the API when performing actions
             *
             * @type {null|string}
             */
            this.token = null;

            /**
             * This is the prototype HTML used to format results (<option>)
             * Tokens are expected in the format "__KEY__"
             *
             * @type {string}
             */
            this.optionTemplate = '';

            /**
             * This is the prototype HTML used to format selections that appear in the Select box.
             * Tokens are expected in the format "__KEY__"
             * These should ideally be one-liners and short!
             *
             * @type {string}
             */
            this.selectionTemplate = '';

            /**
             * Sets if this field should behave as multi-select or single-select.
             *
             * @type {boolean}
             */
            this.multiple = false;

            /**
             * Sets the limit to the number of Multiple items that can be selected.
             *
             * @type {number}
             */
            this.multipleLimit = 0;

            /**
             * Placeholder to display for this field
             *
             * @type {string}
             */
            this.placeholder = '';

            /**
             * Minimum number of characters before the search request should be issued.
             *
             * @type {number}
             */
            this.searchMinLength = 0;

            /**
             * When true, the user can clear the form field selection
             *
             * @type {boolean}
             */
            this.allowClear = false;

            /**
             * Placeholder to display when there is no selection
             * An empty string will omit the placeholder
             *
             * @type {string}
             */
            this.placeholder = '';
        }

        /**
         * Factory
         */
        return {
            create: function() {
                return new InputEntityChoiceConfig();
            }
        };
    }
}(
    angular
));