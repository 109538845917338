'use strict';

(function(angular) {
    var module = angular.module('elmo.directive.dropdown-rating', []);

    const DropdownRatingCtrl = function($scope) {
        var vm = this;

        vm.init = function() {
            vm.rating      = $scope.ratingInstance;
            vm.ratingValue = vm.rating.activeRating.value || null;
        };

        vm.init();
    };

    DropdownRatingCtrl.$inject = ['$scope'];

    const dropdownRatingDirective = function() {
        return {
            restrict: "E",
            template:'<div class="row rating-style"><div class="row"><div class="col-md-12"><div class="col-md-11"><select ng-model="$dropdownCtrl.ratingValue" ng-disabled="$dropdownCtrl.rating.status.disabled" ng-change="$dropdownCtrl.rating.onRatingValueChange($dropdownCtrl.ratingValue)" ng-options="opt.value as opt.title for opt in $dropdownCtrl.rating.ratingSystem" class="form-control"><option>Not Rated</option></select></div><div class="col-md-1 no-padding" ng-show="$dropdownCtrl.rating.activeRating.description"><div class="e-padding-top-10"><span class="glyphicon glyphicon-info-sign" tooltip="[[ $dropdownCtrl.rating.activeRating.description ]]" tooltip-placement="right"></span></div></div></div></div></div>',
            controller: DropdownRatingCtrl,
            scope: {
                ratingInstance: "="
            },
            controllerAs: "$dropdownCtrl",
            link: function(scope, element) {
                element.on('$destroy', function () {
                    scope.$destroy();
                });
            }
        }
    }

    module.directive('dropdownRating', dropdownRatingDirective)
})(angular);
