'use strict';

(function(angular) {
    var module = angular.module('elmo.directive.button-rating', []);

    const ButtonRatingCtrl = function($scope) {
        var vm = this;

        vm.init = function() {
            vm.rating = $scope.ratingInstance;
        };

        vm.init();
    };

    ButtonRatingCtrl.$inject = ['$scope'];

    const buttonRatingDirective = function() {
        return {
            restrict: "E",
            template:'<div class="btn-group rating-style"><button ng-repeat="rating in $buttonCtrl.rating.ratingSystem" data-container="body" data-placement="bottom" data-toggle="tooltip" class="btn has-tooltip btn-primary" data-original-title="[[rating.description]]" tooltip-placement="bottom" tooltip="[[rating.description]]" ng-click="$buttonCtrl.rating.setActiveRating(rating)" ng-class="{\'active\': $buttonCtrl.rating.isRatingActive(rating) }" ng-disabled="$buttonCtrl.rating.status.disabled">[[rating.title]]</button></div>',
            controller: ButtonRatingCtrl,
            scope: {
                ratingInstance: "="
            },
            controllerAs: "$buttonCtrl",
            link: function(scope, element) {
                element.on('$destroy', function () {
                    scope.$destroy();
                });
            }
        }
    }

    module.directive('buttonRating', buttonRatingDirective)
})(angular);
