'use strict';

(function(angular) {

    /**
     * @desc Label Input field type
     * @example <input input-label="{configObject}" />
     */
    angular
        .module('elmo.directives.input-label', [
            'ui.select2',
            'elmo.directives.input-label.service',
            'elmo.directives.input-label.configFactory'
        ])
        .directive('inputLabel', inputLabelDirective);

    /**
     * Angular Label Input directive
     *
     * @param {inputLabelService} inputLabelService
     */
    function inputLabelDirective(inputLabelService) {

        /**
         * Directive definition
         */
        return {
            restrict: 'EA',
            template:'<input type="hidden" ui-select2="select2Options">',
            transclude: true,
            replace: true,
            require: 'ngModel',
            // We want to grab ngModel in the Controller instead of the Linker
            // So we need to lower the priority so ngModel loads on the element first.
            priority: -1,
            scope: {
                config: '=inputLabel'
            },
            compile: function() {
                return {
                    pre: labelInputPreCompiler
                };
            }
        };

        function labelInputPreCompiler($scope, $element, $attrs, ngModelController) {

            /**
             * External to Internal
             */
            ngModelController.$formatters.push(function(value) {
                if (!(typeof value === 'string')) {
                    return [];
                }

                var opts = value.split(',');
                var selection = [];
                for (var i in opts) {
                    if (opts[i].length > 0 ) {
                        selection.push(opts[i]);
                    }
                }
                return selection;
            });

            /**
             * Internal to External
             */
            ngModelController.$parsers.push(function(value) {
                if (!(value instanceof Array)) {
                    value = [];
                }

                return value.join(',');
            });

            /**
             * Query Handler
             * Takes the given input and makes queries to the remote server.
             *
             * @type {Function}
             * @private
             * @param {object} query
             */
            var queryHandler = function (query) {

                // Sanitize page number
                if (!angular.isDefined(query.page) || query.page < 1) {
                    query.page = 1;
                }

                // Request a page worth of data from the server.
                var promise = inputLabelService.findLabels($scope.config, query.term, query.page);
                promise.then(
                    // On Success
                    function (response) {

                        // Validate: Must have a result
                        if (!angular.isDefined(response.data)) {
                            throw "Server failed to return a result set.";
                        }
                        var results = response.data;

                        // Validate: Must have counts
                        if (!angular.isDefined(response.page) || !angular.isDefined(response.page['total-pages'])) {
                            throw "Server failed to return pagination data";
                        }

                        // see if there are more results to page...
                        var more = (response.page['total-pages'] > query.page);

                        // Callback to insert results
                        query.callback({
                            results: results,
                            more: more
                        });

                    },
                    // On Error
                    function (response) {

                        // Clear the result in the UI
                        query.callback({results: [], more: false});
                    }
                );
            };

            /**
             * Format selected items (pills)
             *
             * @param {object} object
             * @param {object} container
             * @returns undefined|{object}
             */
            var formatSelectionHandler = function (object, container) {
                return container.text(object.name).html();
            };

            /**
             * Format the results form searching
             *
             * @param {object} object
             * @param {object} container
             * @param {object} query
             */
            var formatResultHandler = function (object, container, query) {
                return container.text(object.name).html();
            };

            /**
             * Creates the given Choice
             * @param {string} term
             * @return {object}
             */
            var createSearchChoiceHandler = function (term) {
                if ($scope.config.create_enabled) {
                    return {id: term, name: term + ' (new)'};
                }

                // Cannot create
                return false;
            };

            /**
             * Applies config changes to select2
             */
            var configureSelect2 = function() {

                // Apply select2 config via scope.
                $scope.select2Options = {
                    // UX
                    width: '100%',

                    // Options
                    multiple: true,
                    // SimpleTags mode is enabled so that the NAMEs and not the IDs are used to index Labels.
                    // Without SimpleTags we could have to persist the ID for each Label, and it would be impossible
                    // to translate from a CSV string (a,b,c) to the array entries needed by Select2.
                    simple_tags: true,
                    allowClear: true,
                    placeholder: $scope.config.placeholder,

                    // Callbacks
                    query: _.debounce(queryHandler, 250),
                    formatSelection: formatSelectionHandler,
                    formatResult: formatResultHandler,
                    createSearchChoice: createSearchChoiceHandler
                };
            };

            /**
             * Watch the Config object on $scope for changes
             * Then rebuild the select2 config.
             */
            $scope.$watch('config', configureSelect2, true);

            // Build once (even if not ready) to stop ui.select2 throwing errors.
            configureSelect2();
        }
    }

})(
    /** @type {angular} */
    angular
);