'use strict';

(function(angular, window) {

    /**
     * Symfony FOS Router, as an Angular service
     *
     * This service does not allow you access to the raw implementation.
     * If you want more features, you should write something that provides that feature here.
     * @namespace Services
     */
    angular
        .module('elmo.service.router', [])
        .service('router', fosRouterService);

    /**
     * @namespace Router
     * @memberOf Services
     * @desc Wrapper for global FOS JS Router
     */
    function fosRouterService() {

        /**
         * Lazy-load the Router for FOS JS
         *
         * @private
         * @returns {fos.router}
         */
        var getRouter = function() {
            return window.Routing;
        };

        /**
         * Get the sites base URL
         *
         * @return {string}
         */
        this.getBaseUrl = function() {
            return getRouter().getBaseUrl();
        };

        /**
         * Generate route URL from params
         *
         * @param {string} name
         * @param {object} [opt_params=]
         * @param {bool} [absolute=]
         * @return {string}
         */
        this.generate = function(name, opt_params, absolute) {
            return getRouter().generate(name, opt_params, absolute);
        };

    }
})(
    /** @type {angular} */
    angular,
    window
);