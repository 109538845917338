'use strict';

(function(angular) {

    /**
     * Reconfigures cgBusy directive for ELMO common styles.
     * Includes cgBusy, so you don't have to.
     *
     * Example:
     * <div cg-busy="myVm.myPromise">
     *     I get blocked until the promise is resolved.
     * </div>
     *
     * @namespace Elmo
     */
    angular
        .module('elmo.busy', ['cgBusy'])
        .run(function(cgBusyDefaults, $templateCache) {

            // Template
            cgBusyDefaults.wrapperClass = 'elmo-busy unselectable';
            cgBusyDefaults.templateUrl = 'elmo-busy.html';
            cgBusyDefaults.backdrop = false;

            // Timing
            cgBusyDefaults.minDuration = 250;
            cgBusyDefaults.delay = 0;

            // Template Cache
            $templateCache.put(
                'elmo-busy.html',
                '<div class="wrapper">' +
                    '<div class="align">' +
                        '<div class="elmo-busy-spinner"><span class="sr-only">Loading, please wait.</span></div>' +
                    '</div>' +
                '</div>'
            );

        });

})(
    /** @type {angular} */
    angular
);