'use strict';

(function(angular) {

    /**
     * Availability Input Service
     *
     * Managed communication with the Availabilities API
     */
    angular
        .module('elmo.directives.input-availability.service', ['ngResource', 'elmo.service.router'])
        .service('inputAvailabilityService', inputAvailabilityService);

    /**
     * Handles Availability interaction between front-end and the server
     *
     * @param {angular.$q} $q
     * @param {angular.http} $http
     * @param {router} router
     */
    function inputAvailabilityService($q, $http, router) {

    }

})(
    /** @type {angular} */
    angular
);