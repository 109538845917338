'use strict';

(function(angular) {

    angular
        .module('elmo.directives.input-async-file.entity.async-file', [])
        .factory('AsyncFile', AsyncFileEntityFactory);

    /**
     * Factory method for the entity
     */
    function AsyncFileEntityFactory() {

        /**
         * Represents an AsyncFile entity instance
         * These might be temporary files, permanent files, files in upload state, etc.
         *
         * @type AsyncFile
         * @constructor
         */
        function AsyncFile() {
            var proto = this;

            /**
             * Uploaded file object.
             * This is transitory and doesn't hang around long.
             * @type {null|file}
             */
            proto.file = null;

            /**
             * Secure token, representing the file.
             * @type {string}
             */
            proto.token = '';

            /**
             * Filename, passed back by the API.
             * @type {string}
             */
            proto.filename = '';

            /**
             * Human-readable filesize, passed back by the API.
             * @type {string}
             */
            proto.filesize = '';

            /**
             * url for file downloading
             * @type {string}
             */
            proto.downloadUrl = '';

            /**
             * url for file display in browser
             * @type {string}
             */
            proto.displayUrl = '';

            /**
             * File uploader properties
             * @type {{}}
             */
            proto.uploader = {

                /**
                 * When true, the upload is in progress.
                 * @type {boolean}
                 */
                active: false,

                /**
                 * Percent, between 0 and 100, of the upload.
                 * @type {integer}
                 */
                progress: 0
            };

            /**
             * Validation error attached to this file object
             * @type {string}
             */
            proto.error = '';

        }

        var proto = AsyncFile.prototype;

        /**
         * Set the entity data from the API
         *
         * @param {object} data
         */
        proto.setFromApi = function (data) {
            this.token = data.token;
            this.filename = data.filename;
            this.filesize = data.filesize;
            this.downloadUrl = data.downloadUrl;
            this.displayUrl = data.displayUrl;
        };

        /**
         * Retrieve an object that can be used by the API.
         *
         * @returns {{token: *}}
         */
        proto.getApiPayload = function() {
            return {
                token: this.token,
                filename: this.filename,
                filesize: this.filesize,
                downloadUrl: this.downloadUrl,
                displayUrl: this.displayUrl
            };
        };

        /**
         * Returns true if the object contains a valid payload
         *
         * @returns {boolean}
         */
        proto.isValid = function()
        {
            return ((typeof this.token === 'string') && this.token.length > 16);
        };


        return AsyncFile;
    }
})(angular);