'use strict';

(function(angular) {

    /**
     * @desc Config class for InputAsyncFile directive
     * @example new inputLabelConfigClass
     */
    angular
        .module('elmo.directives.input-async-file.configFactory', [])
        .factory('inputAsyncFileConfigFactory', inputAsyncFileConfigFactory);

    /**
     * Configuration class for the InputAsyncFile field
     */
    function inputAsyncFileConfigFactory()
    {
        /**
         * @name AsyncFileConfig
         * @constructor
         */
        function AsyncFileConfig() {

            /**
             * Token passed to the API when making requests
              * @type {null|string}
             */
            this.token = null;

            /**
             * Human-readable max file size
             *
             * @type {string}
             */
            this.file_size_human = '';

            /**
             * Maximum file size allowed by API (in bytes)
             *
             * @type {number}
             */
            this.file_size = 0;

            /**
             * Array of strings, for human-readable mime-type representations.
             * Use this for converying viable file types to the user.
             *
             * @type {Array}
             */
            this.mime_types_human = [];

            /**
             * Machine-readable mime types for acceptable files
             * User this for verifying a target file's mime type.
             *
             * @type {Array}
             */
            this.mime_types = [];

            /**
             * When true, the field will allow multiple files to be uploaded.
             * @type {boolean}
             */
            this.multiple = false;

            /**
             * The maximum number of files that can be uploaded.
             * int zero = no limit (not recommended).
             *
             * @type {number}
             */
            this.multiple_limit = 0;

            /**
             * True if the field should be disabled and not editable.
             * @type {boolean}
             */
            this.disabled = false;

            /**
             * True if the file(s) can be deleted from this input field.
             * When false, the delete controls will not be displayed.
             * @type {boolean}
             */
            this.allow_delete = false;

            /**
             * When true, the form options cannot be modified.
             *
             * @type {boolean}
             */
            this.read_only = false;

            /**
             * When true, the form will be marked $invalid if there are no files.
             *
             * @type {boolean}
             */
            this.required = false;

            /**
             * form field attr
             * @type {string}
             */
            this.attr = {};

            /**
             * symfony form field label
             * @type {string}
             */
            this.label = '';

            /**
             * symfony form field label attr
             * @type {{}}
             */
            this.label_attr = {};
        }

        /**
         * Factory
         */
        return {
            create: function() {
                return new AsyncFileConfig();
            }
        };
    }
}(
    angular
));