'use strict';

(function(angular) {

    /**
     * @desc Config class for InputLabel directive
     * @example new inputLabelConfigClass
     */
    angular
        .module('elmo.directives.input-label.configFactory', [])
        .factory('inputLabelConfigFactory', inputLabelConfigFactory);

    /**
     * Configuration class for the InputLabel field
     * @typedef inputLabelConfigFactory
     */
    function inputLabelConfigFactory()
    {
        /**
         * @name LabelInputChoiceConfig
         * @constructor
         */
        function LabelInputChoiceConfig() {

            /**
             * Token passed to the API when making requests
              * @type {null|string}
             */
            this.token = null;

            /**
             * If true, the field can try and create new labels on the API.
             * This value is replicated and protected by the API Token.
             *
             * @type {boolean}
             */
            this.create_enabled = false;

            /**
             * Number of characters the user must type before results are searched for.
             *
             * @type {number}
             */
            this.search_min_length = 0;

            /**
             * String to use as the placeholder when the select box is empty.
             * Empty string means no placeholder.
             *
             * @type {string}
             */
            this.placeholder = '';
        }

        /**
         * Factory
         */
        return {
            create: function() {
                return new LabelInputChoiceConfig();
            }
        };
    }
}(
    angular
));