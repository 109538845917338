'use strict';

(function(angular) {
    var module = angular.module('elmo.directive.star-rating', []);

    const StarRatingCtrl = function($scope) {
        var vm = this;

        vm.init = function() {
            vm.rating = $scope.ratingInstance;
            vm.ratingValue = vm.rating.activeRating.value || 0;
        };

        vm.init();
    };

    StarRatingCtrl.$inject = ['$scope'];

    const starRatingDirective = function() {
        return {
            restrict: "E",
            template:'<div class="rating rating-style"><input class="rating-sm star-rating-input hide" data-rating-type="[[$starCtrl.rating.ratingStyle]]" type="number" ng-model="$starCtrl.ratingValue" ng-change="$starCtrl.rating.onRatingValueChange($starCtrl.ratingValue)"></div>',
            controller: StarRatingCtrl,
            scope: {
                ratingInstance: "=",
            },
            controllerAs: "$starCtrl",
            link: function(scope, element) {
                const $input = element.find('.star-rating-input');

                function getCaptions () {
                    var captions = {};

                    angular.forEach(scope.ratingInstance.ratingSystem, function(item, key) {
                        captions[item.value] = item.title;
                    });

                    return captions;
                }

                function generateStarRating () {
                    // set input element value.
                    // Cannot set in HTML directly as jQuery rating cannot parse the Angular [[]]
                    $input.attr('value', scope.$starCtrl.ratingValue);

                    // set input element as a star rating
                    $input.rating({
                        stars: scope.ratingInstance.ratingSystem.length,
                        size: 'xs',
                        showClear: false,
                        disabled: scope.ratingInstance.status.disabled,
                        step: 1,
                        min: 0,
                        max: scope.ratingInstance.ratingSystem.length,
                        starCaptions: getCaptions()
                    });
                }

                generateStarRating();

                element.on('$destroy', function () {
                    scope.$destroy();
                });

                // Need a watcher for the disabled status as we need to refresh
                // the jQuery when the
                scope.$watch(function() {
                    return scope.ratingInstance.status.disabled
                }, function(newVal) {
                    $input.rating("refresh", { disabled: newVal });
                })
            }
        }
    }

    module.directive('starRating', starRatingDirective)
})(angular);
