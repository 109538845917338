angular.module('elmo.factory.uiSelectUserViewDataFormatter', []).factory('userViewDataFormatter', function() {
    return function userViewDataFormatter(result, container, query, escapeMarkup) {
        var markup=[];
        var name  = escapeMarkup(result.fullName);
        var email = escapeMarkup(result.email);

        markup.push("<div class='e-padding-5'>");
        markup.push("<p class='result-name e-no-margin e-margin-bottom-negative-20 e-padding-bottom-10'>" + name + "</p>");
        markup.push("<h3 class='result-email e-no-margin'><small>" + email + "</small></h3>");
        markup.push("</div>");

        return markup.join("");
    };
});
