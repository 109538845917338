'use strict';

(function(angular) {

    /**
     * @namespace Elmo
     */
    angular
        .module('elmo.filter', [])
        .filter('percentage', ['$filter', function ($filter) {
            return function (input, decimals) {
                return $filter('number')(input * 100, decimals) + '%';
            };
        }])
        .filter('strReplace', function () {
            return function (input, from, to) {
                input = input || '';
                from = from || '';
                to = to || '';
                return input.replace(new RegExp(from, 'g'), to);
            };
        });

})(
    /** @type {angular} */
    angular
);