'use strict';

(function(angular) {

    /**
     * Label Input Service
     *
     * Managed communication with the Labels API
     */
    angular
        .module('elmo.directives.input-label.service', ['ngResource', 'elmo.service.router'])
        .service('inputLabelService', inputLabelService);

    /**
     * Handles Label interaction between front-end and the server
     *
     * @param {angular.$q} $q
     * @param {angular.http} $http
     * @param {router} router
     */
    function inputLabelService($q, $http, router) {

        /**
         * Find labels matching the given filter
         *
         * @param {ConfigClass} labelConfig
         * @param {string} text
         * @param {int} pageNumber
         * @return {promise}
         */
        this.findLabels = function(labelConfig, text, pageNumber) {

            var deferred = $q.defer();

            // Hack workaround for $http jason-ifying objects
            $.ajax({
                method: 'GET',
                url: router.generate('elmo_app-common_label-choice'),
                data: {
                    'token': labelConfig.token,
                    'page': {
                        'number': pageNumber
                    },
                    'filter': {
                        'text': text
                    }
                }
            })
                .success(function(responseData) {
                    deferred.resolve(responseData);
                })
                .error(function () {
                    deferred.reject();
                });

            return deferred.promise;
        };

    }

})(
    /** @type {angular} */
    angular
);