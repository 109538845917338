'use strict';

(function (angular) {

    /**
     * @desc Breadcrumb Input field type
     * @example <input input-breadcrumb />
     */
    angular
        .module('elmo.directives.input-breadcrumb', [])
        .directive('elmoBreadcrumb', inputBreadcrumbDirective);

    /**
     * Angular Breadcrumb Breadcrumb directive
     *
     */
    function inputBreadcrumbDirective() {

        /**
         * Directive definition
         */
        var directive = {
            restrict: 'E',
            template:'<div><ol class="breadcrumb"><li ng-repeat="breadcrumb in breadcrumbs track by $index" ng-class="{\'active\': vm.isLastBreadcrumb($index)}"><a ng-if="!vm.isLastBreadcrumb($index)" ng-href="[[ breadcrumb.getHref() ]]"><span ng-class="breadcrumb.getIcon()"></span> [[ breadcrumb.getText() ]]</a> <span ng-if="vm.isLastBreadcrumb($index)"><span ng-class="breadcrumb.getIcon()"></span> [[ breadcrumb.getText() ]]</span></li></ol></div>',
            require: "ngModel",
            scope: {
                breadcrumbs: "=ngModel",
            },
            link: function (scope, element, attributes, ngModelCtrl) {
                scope.vm = {};
                scope.vm.isLastBreadcrumb = function($index) {
                    return $index === (scope.breadcrumbs.length-1);
                }
            }
        };

        return directive;
    }
})(
    /** @type {angular} */
    angular
);