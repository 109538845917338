'use strict';

(function (angular) {

    angular
        .module('elmo.app.common.entity.breadcrumb-item',[])
        .factory('ElmoBreadcrumbItem', EntityFactory);

    /**
     * Factory method for the entity
     */
    function EntityFactory() {

        /**
         * Breadcrumb Item
         */
        function breadcrumbItem() {

            var thisObj = this;

            // the text/title of the breadcrumb item
            var text = '';

            // the icon of the breadcrumb item
            var icon = '';

            // the href link of the breadcrumb
            var href = '';

            //SETTERS
            thisObj.setText = function (textArg) {
                text = textArg;
                return thisObj;
            }

            thisObj.setIcon = function (iconArg) {
                icon = iconArg;
                return thisObj;
            }

            thisObj.setHref = function (hrefArg) {
                href = hrefArg;
                return thisObj;
            }

            //GETTERS
            thisObj.getText = function () {
                return text;
            }

            thisObj.getIcon = function () {
                return icon;
            }

            thisObj.getHref = function () {
                return href;
            }
        }

        return breadcrumbItem;
    }
})(angular);