'use strict';

/**
 * Global Toggle Checkbox for bootstrap-toggle
 */
angular.module('elmo.directives.toggleCheckbox', [])
    .directive('toggleCheckbox', function($timeout) {

        /**
         * Directive
         */
        return {
            restrict: 'A',
            transclude: true,
            replace: false,
            require: 'ngModel',
            priority: 1500,
            scope: {
                ngModel:'='
            },
            link: function ($scope, $element, $attr, ctrl) {

                // update model from Element
                var updateModelFromElement = function() {
                    // If modified
                    var checked = $element.prop('checked');
                    if (checked != ctrl.$viewValue) {
                        // Update ngModel
                        ctrl.$setViewValue(checked);
                        
                        //Use timeout to prevent $digest already in progress error
                        $timeout(function(){
                            $scope.$apply();
                        });
                    }
                };

                // Update input from Model
                var updateElementFromModel = function(newValue) {
                    // Update button state to match model
                    $element.prop('checked', newValue).change();
                };

                // Observe: Element changes affect Model
                $element.on('change', function() {
                    updateModelFromElement();
                });

                // Observe: ngModel for changes
                $scope.$watch('ngModel', function(newValue) {
                    updateElementFromModel(newValue);
                });

                // Observe: disabled attribute set by ngDisabled
                $scope.$watch(function() {
                    return $($element).attr('disabled');
                }, function(newVal) {
                    $($element).bootstrapToggle(! newVal ? "enable" : "disable");
                });

                // Initialise BootstrapToggle
                $timeout(function() {
                    $element.bootstrapToggle();
                });
            }
        };
    });