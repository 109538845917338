'use strict';

(function(angular) {

    var module = angular
        .module('elmo.directive.elmo-rating', [
            'elmo.service.ratingFactory',
            'elmo.directive.button-rating',
            'elmo.directive.slider-rating',
            'elmo.directive.star-rating',
            'elmo.directive.dropdown-rating'
        ]);

    const ratingController = function($scope, ratingFactory) {
        var vm = this;

        vm.init = function() {
            vm.ratingStyle = $scope.ratingStyle;

            // Register the rating instance
            vm.ratingInstance = ratingFactory(generateRatingObj());
        };

        function generateRatingObj() {
            return {
                activeRating: $scope.activeRating,
                ratingSystem: $scope.ratingSystem,
                status: $scope.status || {},
                ratingStyle: $scope.ratingStyle,
                onChange: $scope.onChange
            };
        };
    };

    ratingController.$inject = ['$scope', 'ratingFactory'];

    const ratingDirective = function() {
        return {
            restrict: 'E',
            scope: {
                ratingSystem: '=',
                activeRating: '=',
                status: '=',
                ratingStyle: "=",
                onChange: '&'
            },
            controller: ratingController,
            controllerAs: "$ctrl",
            template:'<div class="col-12" ng-init="$ctrl.init()" ng-switch="$ctrl.ratingStyle"><div ng-switch-default><alert type="\'danger\'"><i class="glyphicon glyphicon-warning-sign"></i> <span class="font-weight-600">No rating style found</span></alert></div><div class="rating-style" ng-switch-when="0"><button-rating rating-instance="$ctrl.ratingInstance"></button-rating></div><div class="rating-style" ng-switch-when="1"><slider-rating rating-instance="$ctrl.ratingInstance"></slider-rating></div><div class="rating-style" ng-switch-when="2"><star-rating rating-instance="$ctrl.ratingInstance"></star-rating></div><div class="rating-style" ng-switch-when="3"><dropdown-rating rating-instance="$ctrl.ratingInstance"></dropdown-rating></div></div>',
            link: function (scope, element) {
                element.on('$destroy', function () {
                    scope.$destroy();
                });
            }
        }
    };

    module.directive('elmoRating', ratingDirective);
})(angular);
