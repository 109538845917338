'use strict';

(function(angular) {
    var module = angular.module('elmo.directive.slider-rating', [
        'elmo.directives.slider',
        'ui.bootstrap'
    ]);

    const captionClass = [
        'label label-danger',
        'label label-warning',
        'label label-warning',
        'label label-info',
        'label label-info',
        'label label-primary',
        'label label-primary',
        'label label-success'
    ];

    const SliderRatingCtrl = function($scope) {
        var vm = this;

        vm.init = function() {
            vm.rating      = $scope.ratingInstance;
            vm.ratingValue = vm.rating.activeRating.value || 0;
        };

        vm.getCaptionClass = function(ratingItem) {
            var index = ratingItem ? ratingItem.value - 1 : 0;
            var lastItemIndex = captionClass.length - 1;

            return captionClass[index] || captionClass[lastItemIndex];
        };

        vm.init();
    };

    const sliderRatingDirective = function() {
        return {
            restrict: "E",
            template:'<div class="rating-style"><div class="btn-group col-md-12 row"><div ui-slider min="1" max="[[$sliderCtrl.rating.ratingSystem.length]]" class="slider-rating-wrapper e-margin-left-0" ng-model="$sliderCtrl.ratingValue" ng-click ng-change="$sliderCtrl.rating.onRatingValueChange($sliderCtrl.ratingValue)" ng-disabled="$sliderCtrl.rating.status.disabled"></div><div class="slider-caption" tooltip="[[ $sliderCtrl.rating.activeRating.description ]]" tooltip-placement="top"><span ng-if="!$sliderCtrl.rating.activeRating.id" class="label label-default">Not Rated</span> <span ng-if="$sliderCtrl.rating.activeRating.id" class="label [[$sliderCtrl.getCaptionClass($sliderCtrl.rating.activeRating)]]">[[ $sliderCtrl.rating.activeRating.title ]]</span></div></div></div>',
            controller: SliderRatingCtrl,
            controllerAs: "$sliderCtrl",
            scope: {
                ratingInstance: "="
            },
            link: function(scope, element) {
                element.on('$destroy', function () {
                    scope.$destroy();
                });
            }
        }
    };

    SliderRatingCtrl.$inject = ['$scope'];

    module.directive('sliderRating', sliderRatingDirective)
})(angular);
