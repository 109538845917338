'use strict';

(function(angular) {

    /**
     * @desc Config class for InputAvailability directive
     * @example new inputAvailabilityConfigClass
     */
    angular
        .module('elmo.directives.input-availability.configFactory', [])
        .factory('inputAvailabilityConfigFactory', inputAvailabilityConfigFactory);

    /**
     * Configuration class for the InputAvailability field
     */
    function inputAvailabilityConfigFactory()
    {
        /**
         * @name ConfigClass
         * @constructor
         */
        function ConfigClass() {

            /**
             * Default properties
             */
            this.token = null;
            this.types = null;
            this.children = null;
            this.entity_choice_mapper = null;
        }

        /**
         * Factory
         */
        return {
            create: function() {
                return new ConfigClass();
            }
        };
    }
}(
    angular
));