'use strict';

(function(angular) {

    /**
     * @desc Entity Choice Input field type
     * @example <input input-entity-choice="{configObject}" />
     */
    angular
        .module('elmo.directives.input-entity-choice', [
            'ui.select2',
            'elmo.directives.input-entity-choice.service',
            'elmo.directives.input-entity-choice.configFactory'
        ])
        .directive('inputEntityChoice', inputEntityChoiceDirective);

    /**
     * Angular Label Input directive
     *
     * @param {InputEntityChoiceService} inputEntityChoiceService
     */
    function inputEntityChoiceDirective(inputEntityChoiceService)
    {
        /**
         * Directive definition
         */
        return {
            restrict: 'EA',
            template:'<input type="hidden" ui-select2="select2Options">',
            transclude: true,
            replace: true,
            require: 'ngModel',
            // We want to grab ngModel in the Controller instead of the Linker
            // So we need to lower the priority so ngModel loads on the element first.
            priority: -1,
            scope: {
                config: '=inputEntityChoice'
            },
            compile: function() {
                return {
                    pre: EntityChoiceDirectivePreCompile
                }
            }
        };

        /**
         * Pre Compile
         * Beat ui-select2 compile. Scope options can be compiled here.
         *
         * @param $scope
         * @param $element
         */
        function EntityChoiceDirectivePreCompile($scope, $element) {

            /**
             * Query Handler
             * Takes the given input and makes queries to the remote server.
             *
             * @type {Function}
             * @private
             * @param {object} query
             */
            var queryHandler = function (query) {
                // Sanitize page number
                if (!angular.isDefined(query.page) || query.page < 1) {
                    query.page = 1;
                }

                /**
                 * Called when Query request succeeds
                 * @param {object} response
                 */
                var successCallback = function(response)
                {
                    // Validate: Must have a result
                    if (!angular.isDefined(response.data)) {
                        throw "Server failed to return a result set.";
                    }
                    var results = response.data;

                    // Validate: Must have counts
                    if (!angular.isDefined(response.page) || !angular.isDefined(response.page['total-pages'])) {
                        throw "Server failed to return pagination data";
                    }

                    // see if there are more results to page...
                    var more = (response.page['total-pages'] > query.page);

                    // Callback to insert results
                    query.callback({
                        results: results,
                        more: more
                    });
                };

                /**
                 * Called when Query request results in an error
                 * @param response
                 */
                var errorCallback = function(response)
                {
                    // Clear the result in the UI
                    query.callback({ results: [], more: false });
                };

                // Request a page worth of data from the server.
                var promise = inputEntityChoiceService.searchEntities($scope.config, query.term, query.page);
                promise.then(successCallback, errorCallback);
            };

            /**
             * Replaces the "__TOKENS__" in {template} with values from {tokens}
             * where the Key == Token name.
             * FIXME: This is susceptible to token injection (eg. content contains __SOMETHING__ and subsequently gets replaced). Fix this eventually.
             *
             * @param template
             * @param tokens
             * @returns {*}
             */
            var replaceTokens = function(template, tokens) {
                for (var k in tokens) {
                    var token = k.toUpperCase().trim();
                    template = template.replace('__'+token+'__', tokens[k]);
                }
                return template;
            };

            /**
             * Format selected items (pills)
             *
             * @param {object} object
             * @param {object} container
             * @returns {string}
             */
            var formatSelectionHandler = function (object, container) {

                // Replace members on the template
                var tpl = $scope.config.selectionTemplate;
                tpl = replaceTokens(tpl, object);
                return tpl;
            };

            /**
             * Format the results form API Search response
             *
             * @param {object} object
             * @param {object} container
             * @param {object} query
             * @return {string}
             */
            var formatResultHandler = function(object, container, query) {

                // Replace members on the template
                var tpl = $scope.config.optionTemplate;
                tpl = replaceTokens(tpl, object);
                return tpl;
            };

            /**
             * Initialise selection based on localData
             *
             * @param {object} element
             * @param {Function} callback
             * @returns {object}
             */
            var initSelectionHandler = function (element, callback) {
                // Retrieve the ngModel controller
                var ngModel = $element.controller('ngModel');
                callback(ngModel.$modelValue);
            };

            var configureSelect2 = function() {
                // Apply select2 config via scope.
                if(!angular.isObject($scope.config)) {
                    $scope.select2Options = {
                        query: _.debounce(queryHandler, 250)
                    };
                    return false;
                }

                $scope.select2Options = {
                    // UX
                    width: '100%',

                    // Config
                    multiple: $scope.config.multiple,
                    maximumSelectionSize: $scope.config.multipleLimit,
                    allowClear: $scope.config.allowClear,
                    placeholder: $scope.config.placeholder,

                    // Callbacks
                    query: _.debounce(queryHandler, 250),
                    formatSelection: formatSelectionHandler,
                    formatResult: formatResultHandler,
                    initSelection: initSelectionHandler
                };
            };

            /**
             * Watch the Config object on $scope for changes
             * Then rebuild the select2 config.
             */
            $scope.$watch('config', configureSelect2, true);

            // Build once (even if not ready) to stop ui.select2 throwing errors.
            configureSelect2();
        }

    }

})(
    /** @type {angular} */
    angular
);
