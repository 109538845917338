'use strict';

(function(angular) {

    /**
     * Async File Input Service
     *
     * Manages communication with the Async File API
     */
    angular
        .module('elmo.directives.input-async-file.service', [
            'elmo.service.router',
            'elmo.directives.input-async-file.entity.async-file'
        ])
        .service('inputAsyncFileService', inputAsyncFileService);

    /**
     * Handles Async File interaction between front-end and the server
     *
     * @param {angular.$q} $q
     * @param {angular.http} $http
     * @param {router} router
     * @param {AsyncFile} AsyncFile
     */
    function inputAsyncFileService($q, $http, router, AsyncFile) {

        /**
         * Serialize an array of AsyncFile objects to their API payload
         * @param {array<AsyncFile>} files
         * @return string
         */
        this.serializeAsyncFiles = function(files)
        {
            var payload = [];

            if (files instanceof Array) {
                for (var i in files) {
                    var file = files[i];

                    // If valid, get the API payload.
                    if (file instanceof AsyncFile && file.isValid()) {
                        payload.push(file.getApiPayload());
                    }
                }
            }

            return JSON.stringify(payload);
        };

        /**
         * Deserialize a string API payload to an array of AsyncFile objects.
         * @param {string} payloadStr
         * @return {array<AsyncFile>}
         */
        this.deserializeAsyncFiles = function(payloadStr)
        {
            var payload = [];

            // Deserialize
            // This can fail with bad JSON strings or other data types.
            try {
                payload = JSON.parse(payloadStr);
            } catch (ex) {
                // Capture and fail with a warn. This shouldn't happen in prod.
                console.warn('AsyncFile failed to parse payload string:' + payloadStr);
            }

            var outData = [];

            // Denormalize only if array of data
            if ((payload instanceof Array)) {

                // Parse each to objects
                for (var i in payload) {
                    var item = payload[i];

                    // Skip anything with a bad token
                    if (!item.token) {
                        continue;
                    }

                    // Parse objects
                    var object = new AsyncFile();
                    object.setFromApi(item);
                    outData.push(object);
                }
            }

            return outData;
        };

        /**
         * Generate a download URL for the igven file object
         *
         * @param {AsyncFile} file
         * @return {string}
         */
        this.getDownloadUrl = function(file) {
            return router.generate('elmo_file.apiv0.async_file_type.download', {token: file.token});
        };

    }

})(
    /** @type {angular} */
    angular
);