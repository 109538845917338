'use strict';

(function(angular) {

    /**
     * Form Config Mapper class
     *
     * @class FormConfigMapper
     * @constructor
     */
    function FormConfigMapper($http, $q) {
        // Dependencies
        this.$http = $http;
        this.$q = $q;

        // Vars
        this.map = {};
    }

    /**
     * Add the given FieldName mapping for the Config object
     *
     * @public
     * @param {string} fieldName
     * @param {object} configObject
     */
    FormConfigMapper.prototype.add = function(fieldName, configObject) {

        // Create mapping
        this.map[fieldName] = {fieldName:fieldName, config:configObject};
    };

    /**
     * Perform mapping of Form options to Mappings
     *
     * @private
     * @param {object} formOptions
     * @param {object} configMapping
     */
    var mapFormOptions = function(formOptions, configMapping) {

        // Validate formOptions has expected mappings
        for (var i in configMapping) {

            // Get the mapping
            var map = configMapping[i];

            // Validate: Must have child field mappings
            if (formOptions.hasOwnProperty('children') === false) {
                throw "Form OPTIONS does not contain any children for mapping";
            }

            // Validate: Mapped field must exist in formOptions
            if (formOptions.children.hasOwnProperty(map.fieldName) === false) {
                throw "Form OPTIONS did not contain expected field name '"+map.fieldName+"'.";
            }

            // Get the form options object
            var options = formOptions.children[map.fieldName];

            // Populate the individual config object fields
            for (var k in options) {

                // Map the option if it has a counterpart in config
                if (map.config.hasOwnProperty(k) === true) {
                    map.config[k] = options[k];
                }
            }
        }
    };

    /**
     * Load the Form Config from the given URL
     *
     * @public
     * @param {object} request
     * @return {ng.promise}
     */
    FormConfigMapper.prototype.load = function(request) {

        var deferred = this.$q.defer();

        // Merge request
        request = angular.extend({method: 'OPTIONS'}, request);

        // Validate request
        if (!request.url) { throw 'Request must have key "url".'; }

        // Make request
        var promise = this.$http(request);

        // Callback: On Failure
        var callbackFail = function() {
            deferred.reject();
        };

        // Callback: On Success
        var callbackSuccess = function(data) {
            try {
                // Do the internal mapping
                mapFormOptions(data, this.map);
                deferred.resolve();
            } catch (ex) {
                callbackFail.apply(this);
                throw ex;
            }
        };

        // Bind callbacks
        promise.success(callbackSuccess.bind(this));
        promise.error(callbackFail.bind(this));
        return deferred.promise;
    };


    /**
     * Symfony Form Config Mapper
     * Use this service to interface with a Symfony Form
     */
    angular
        .module('elmo.service.form-config-mapper', [])
        .service('formConfigMapper', function ($http, $q) {

        /**
         * Public methods
         */
        return {

            /**
             * Factory method
             * @returns {FormConfigMapper}
             */
            createMapper: function() {
                return new FormConfigMapper($http, $q);
            }
        };
    });
})(
    /** @type {angular} */
    angular
);