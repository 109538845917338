'use strict';

(function (angular) {

    /**
     * @desc Availability Input field type
     * @example <input input-availability />
     */
    angular
        .module('elmo.directives.input-availability', [
            'ui.select2',
            'elmo.directives.input-availability.configFactory',
            'elmo.directives.input-availability.service'
        ])
        .directive('inputAvailability', inputAvailabilityDirective);

    /**
     * Angular Availability Availability directive
     *
     */
    function inputAvailabilityDirective() {
        /**
         * Directive definition
         */
        var directive = {
            restrict: 'E',
            template:'<div class="form-group"><select ng-options="availabilityType as availabilityType.label for availabilityType in directiveConfig.types track by availabilityType.type_id" class="form-control" ng-model="selectedAvailability.type"></select></div><div class="form-group" ng-show="display.showSpecificUser"><label class="control-label required">Please select users</label> <input type="hidden" ng-attr-id="[[ appParams.symfonyFormName ]]_availability_specific_users" input-entity-choice="directiveConfig.children.availability_users" ng-model="selectedAvailability.users"></div><div class="form-group" ng-show="display.showGroup"><label class="control-label required">Please select a group</label> <input type="hidden" ng-attr-id="[[ appParams.symfonyFormName ]]_availability_group" input-entity-choice="directiveConfig.children.availability_group" ng-model="selectedAvailability.group"></div><div class="form-group" ng-show="display.showDynamicGroup"><label class="control-label required">Please select a group</label> <input type="hidden" ng-attr-id="[[ appParams.symfonyFormName ]]_availability_dynamic_group" input-entity-choice="directiveConfig.children.availability_dynamic_group" ng-model="selectedAvailability.dynamic_group"></div>',
            require: "ngModel",
            scope: {
                selectedAvailability: "=ngModel",
                name: "@",
                directiveConfig: "="
            },
            link: function (scope, element, attributes, ngModelCtrl) {

                scope.display = {
                    showGroup: false,
                    showSpecificUser: false
                };

                //set default custom group id from
                // \Elmo\UserBundle\Entity\Group::INTERNAL_TYPE_PRIVATE_SPECIFIC_USERS
                // this id is overwritten by the supplied value in appParams
                // when directiveConfig scope watcher is fired
                scope.appParams = {
                    customGroupTypeId: 2,
                    symfonyFormName: ''
                };

                scope.$watch('selectedAvailability', function (newValue, oldValue) {
                    scope.display.showGroup = scope.getDisplaySetting('group');         //AvailabilityChoiceType::STATIC_GROUP_FORM_NAME

                    scope.display.showDynamicGroup = scope.getDisplaySetting('dynamic_group'); //AvailabilityChoiceType::DYNAMIC_GROUP_FORM_NAME

                    scope.display.showSpecificUser = scope.getDisplaySetting('users');

                    //if its empty, set validity for required to false
                    ngModelCtrl.$setValidity("required", !ngModelCtrl.$isEmpty(newValue));
                }, true);

                /**
                 * Returns true for empty model and false if model is valid and not empty
                 * @return boolean
                 * @param availabilityModelValue
                 */
                ngModelCtrl.$isEmpty = function (availabilityModelValue) {

                    //CONDITION 1:
                    // No object
                    if(typeof availabilityModelValue !== 'object') {
                        return true;
                    }

                    //CONDITION 2:
                    // If no type is set
                    if(!availabilityModelValue.type) {
                        return true;
                    }

                    //CONDITION 3:
                    // If no type_id is set
                    if(!availabilityModelValue.type.type_id) {
                        return true;
                    }

                    //CONDITION 4:
                    // if type is group (Availability::STATIC_GROUP)
                    if(availabilityModelValue.type.type_id == 5) {
                        return(
                            //if no group is selected
                            !availabilityModelValue.group
                            ||
                            // if its a custom group (specific users)
                            (availabilityModelValue.group.group_type == scope.appParams.customGroupTypeId)
                        );
                    }

                    //CONDITION 5:
                    // if type is group (Availability::DYNAMIC_GROUP)
                    if(availabilityModelValue.type.type_id == 6) {
                        return(
                            //if no group is selected
                            !availabilityModelValue.dynamic_group
                            ||
                            // if its a custom group (specific users)
                            (availabilityModelValue.dynamic_group.group_type == scope.appParams.customGroupTypeId)
                        );
                    }

                    //CONDITION 6:
                    //if specific users and no user is selected(Availability::SPECIFIC_USERS)
                    if (availabilityModelValue.type.type_id == 4) {
                        return (
                            //if users aren't specified
                            !availabilityModelValue.users
                            ||
                            // or users is an empty array
                            (
                                angular.isArray(availabilityModelValue.users)
                                &&
                                availabilityModelValue.users.length == 0
                            )
                        );
                    }

                    return false;
                };

                /**
                 * @param {AppParams} appParamsObj
                 */
                scope.$watch('directiveConfig.children', function (newValue, oldValue) {
                    /**
                     * The code below checks if appParams object is provided in the config;
                     * Once an appParams property is found in directiveConfig,
                     * the code below loops through and assigns all properties directly to
                     * scope.directiveConfig.children so that the directive bound to this property
                     * can work
                     */
                    // if its an object
                    if (angular.isObject(newValue)) {
                        var appParamsObj = scope.directiveConfig.appParams || null;
                        //if appParams are found in directiveConfig
                        if (appParamsObj !== null) {

                            setFromAppParams(scope, appParamsObj);

                            var availbilityFormId = appParamsObj.availabilityFormId;
                            //if form name is provided
                            if (angular.isString(availbilityFormId) && availbilityFormId.length > 0) {
                                for (var propName in newValue) {
                                    if (propName.indexOf(availbilityFormId) !== -1) {
                                        var sanitizedPropName = propName.replace(availbilityFormId + '_', '');
                                        newValue['availability_' + sanitizedPropName] = newValue[availbilityFormId + "_" + sanitizedPropName];
                                    }
                                }
                            }
                        }
                    }
                });

                /**
                 * Returns true if the Availability type is set to a the specified typeIdString.
                 *   Takes 'users'|'group'|'dynamic_group' defined as constants in
                 *   \Elmo\AppBundle\Form\Type\AvailabilityChoice\AvailabilityChoiceType
                 *
                 * @param typeIdString
                 * @returns {boolean}
                 */
                scope.getDisplaySetting = function (typeIdString) {

                    if (!angular.isObject(scope.selectedAvailability)) {
                        return false;
                    }

                    if (!scope.selectedAvailability.hasOwnProperty('type')) {
                        return false;
                    }

                    if (!scope.selectedAvailability.type.hasOwnProperty('type_id')) {
                        return false;
                    }

                    if (scope.directiveConfig.entity_choice_mapper !== null) {
                        if (!scope.directiveConfig.entity_choice_mapper.hasOwnProperty(scope.selectedAvailability.type.type_id)) {
                            return false;
                        }

                        if (scope.directiveConfig.entity_choice_mapper[scope.selectedAvailability.type.type_id] == typeIdString) {
                            return true;
                        }
                    }

                    return false;
                }
            }
        };

        var setFromAppParams = function (scope, appParamsObj) {
            //if property is provided, overwrite it
            if(appParamsObj.customGroupTypeId) {
                scope.appParams.customGroupTypeId = appParamsObj.customGroupTypeId;
            }

            if(appParamsObj.symfonyFormName) {
                scope.appParams.symfonyFormName = appParamsObj.symfonyFormName;
            }
        };

        return directive;
    }
})(
    /** @type {angular} */
    angular
);