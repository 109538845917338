'use strict';

(function(angular) {

    /**
     * Breadcrumb Service
     *
     */
    angular
        .module('elmo.directives.breadcrumb.service', [
            'elmo.app.common.entity.breadcrumb-item'
        ])
        .service('ElmoCommonBreadcrumbService', breadcrumbService);

    function breadcrumbService($rootScope, ElmoBreadcrumbItem) {

        var eventId = 'elmo.breadcrumb.event';

        this.breadcrumbItems = [];

        this.addBreadcrumbItem = function (breadcrumbItem) {
            if(!(breadcrumbItem instanceof ElmoBreadcrumbItem)) {
                throw "Invalid object. Expected object of type BreadcrumbItem";
            }
            this.breadcrumbItems.push(breadcrumbItem);
            $rootScope.$emit(eventId, this.breadcrumbItems);
        }

        this.getBreadcrumbItems = function () {
            return this.breadcrumbItems;
        }


        this.setHeaderCallback = function (callback) {
            if(!(typeof callback === 'function')) {
                throw ("setHeaderCallback expects callback to be of type 'function'.")
            }
            $rootScope.$on(eventId, function (event, data) {
                callback(data);
            });
        }

        this.clearBreadcrumbs = function() {
            this.breadcrumbItems = [];
        }
    }
})(
    /** @type {angular} */
    angular
);
