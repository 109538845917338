'use strict';

/**
 * WARNING: This directive requires an updated version of angular-file-upload to work!
 * Be sure to include: <script type="text/javascript" src="/js/angularjs-modules/angular-file-upload/angular-file-upload.min.js"></script>
 * TODO: Remove this requirement by sorting out the terrible global dependencies.
 */
(function(angular) {

    /**
     * @desc Async File Input field type
     * @example <input input-async-file="{configObject}" />
     */
    angular
        .module('elmo.directives.input-async-file', [
            'elmo.directives.input-async-file.service',
            'elmo.directives.input-async-file.configFactory',
            'elmo.directives.input-async-file.entity.async-file',
            'elmo.service.router',
            'angularFileUpload'
        ])
        .directive('inputAsyncFile', inputAsyncFileDirective);

    /**
     * Angular Async File Input directive
     *
     * @param {angular.timeout} $timeout
     * @param {inputAsyncFileService} inputAsyncFileService
     */
    function inputAsyncFileDirective($timeout, inputAsyncFileService) {

        return {
            restrict: 'EA',
            template:'<div class="row"><div class="col-md-12"><table class="table"><tbody><tr ng-repeat="model in vm.files track by $index" ng-class="{\'alert alert-danger\': model.error.length}"><td width="30%"><div ng-if="(!model.isValid())"><input type="file" ng-model="model" nv-file-select uploader="vm.uploader" options="{modelIndex:$index}" id="fileInput_[[ $index ]]" required ng-disabled="(model.uploader.active || vm.disabled)"></div><div ng-if="(model.isValid())"><span class="glyphicon glyphicon-file"></span> <strong>[[ model.filename ]]</strong></div></td><td><div ng-if="(model.uploader.active)"><progressbar class="text-center nopadding progress-striped" value="model.uploader.progress" type="info">[[ model.uploader.progress ]] %</progressbar></div><div ng-if="(!model.uploader.active && model.error.length)">[[ model.error ]]</div><div ng-if="(!model.uploader.active && !model.error.length)"><span class="text-muted">[[ model.filesize ]]</span></div></td><td width="1%"><a class="btn btn-xs btn-primary" ng-if="( vm.config.allow_download && model.isValid() )" ng-href="[[ vm.getDownloadUrl(model) ]]"><span class="glyphicon glyphion-download"></span> <span class="sr-only">Download file [[ model.filename ]]</span></a></td><td width="1%"><button type="button" class="btn btn-xs btn-primary" ng-if="vm.isDeleteVisible(model)" ng-click="vm.deleteFile(model)"><span class="glyphicon glyphicon-trash"></span> <span class="sr-only">Remove file [[ model.filename ]]</span></button></td></tr></tbody><tfoot ng-if="vm.isUploaderVisible()"><tr><td colspan="4"><a type="button" id="addFile" class="btn btn-primary btn-sm" ng-click="vm.addFile()" ng-disabled="(vm.disabled)"><span class="glyphicon glyphicon-plus"></span>[[ vm.buttonLabel ]]</a></td></tr></tfoot></table></div></div>',
            transclude: true,
            replace: true,
            require: '^ngModel',
            // We want to grab ngModel in the Controller instead of the Linker
            // So we need to lower the priority so ngModel loads on the element first.
            priority: -1,
            scope: {
                config: '=inputAsyncFile',
                // Helper is optional, and is used to aid the parent scope.
                helper: '=?helper',
                getDisplayUrl: '&?getDisplayUrl',
                fieldName: '@?fieldName'
            },
            controller: AsyncFileDirectiveController,
            controllerAs: 'vm'
        };

        /**
         * Controller
         *
         * @param {ng.IScope} $scope
         * @param {JQuery} $element
         * @param {FileUploader} FileUploader
         * @param {Services.Router} router
         * @param {AsyncFile} AsyncFile
         * @constructor
         */
        function AsyncFileDirectiveController($scope, $element, FileUploader, router, AsyncFile)
        {
            /**
             * View Model
             */
            var vm = this;

            vm.disabled = false;
            vm.error = null;
            vm.multiple = true;
            vm.buttonLabel = 'Add File';

            /**
             * Helper grants access to directive functions in the parent scope.
             * @type {{}}
             */
            $scope.helper = {
                toString: function() {
                    return ''
                        + '<div class="text-left">'
                        + '<p>'
                        + '<strong>Allowed file types</strong><br/>'
                        + this.getMimeTypes().join('<br/>')
                        + '</p>'
                        + '<p>'
                        + '<strong>Max file size</strong><br/>'
                        + this.getMaxFileSize()
                        + '</p>'
                        + '</div>';
                },
                getMimeTypes: function() {
                    return vm.config.mime_types_human;
                },
                getMaxFileSize: function() {
                    return vm.config.file_size_human;
                },
                getError: function () {
                    return vm.error;
                }
            };

            /**
             * File array container
             * @type {AsyncFile}
             */
            vm.files = [];

            /**
             * Configuration container
             * @type {AsyncFileConfig}
             */
            vm.config = {};

            /**
             * Deep watch config, and reload it to vm.
             */
            $scope.$watch('config', function(newVar, oldVar) {
                vm.config = newVar;
            }, true);


            /**
             * Uploader Configuration
             * @type {FileUploader}
             */
            vm.uploader = new FileUploader({
                url: router.generate('elmo_file.apiv0.async_file_type.upload'),
                autoUpload: true
            });

            /**
             * Mime Type filter
             * Prevents users adding files that do not fit the MimeType filter.
             */
            vm.uploader.filters.push({
                name: 'mimeFilter',
                fn: function(item, options) {
                    vm.error = null;
                    return $.inArray(item.type, vm.config.mime_types) !== -1;
                }
            });

            /**
             * File Size filter
             * Prevents user adding files that exceed the file size limit.
             */
            vm.uploader.filters.push({
                name: 'sizeFilter',
                fn: function(item, options) {
                    vm.error = null;
                    return item.size <= vm.config.file_size;
                }
            });

            vm.uploader.onWhenAddingFileFailed = function(item, filter, options) {
                switch(filter.name) {
                    case 'sizeFilter':
                        vm.error = "File is too large: File must not exceed " + vm.config.file_size_human + ".";
                        break;

                    case 'mimeFilter':
                        vm.error = "File type is not allowed: Valid types are " + vm.config.mime_types_human.join(', ') + '.';
                        break;
                }
            };

            /**
             * Before execution of the Upload
             * @param item
             */
            vm.uploader.onBeforeUploadItem = function(item) {

                // Inject the Field Token for the upload endpoint
                item.formData.push({
                    token: vm.config.token
                });

                // Reset the state of the upload
                var file = vm.files[item.modelIndex];
                file.uploader.active = true;
                file.uploader.progress = 0;
                file.error = '';
            };

            /**
             * Process update during upload
             *
             * @param item
             * @param progress
             */
            vm.uploader.onProgressItem = function(item, progress) {
                var file = vm.files[item.modelIndex];
                file.uploader.progress = progress;

                $scope.$apply(); // Nudge scope update
            };

            /**
             * Success response after upload completes
             *
             * @param item
             * @param response
             * @param status
             * @param headers
             */
            vm.uploader.onSuccessItem = function(item, response, status, headers) {
                var file = vm.files[item.modelIndex];
                file.uploader.active = false;

                // Read data from the API response
                file.setFromApi(response);
                // return the display url after upload file
                $scope.getDisplayUrl({
                    'fieldName': $scope.fieldName,
                    'fileIndex':item.modelIndex,
                    'fileDisplayUrl':file.displayUrl
                });
            };

            /**
             * Error response when upload fails.
             *
             * @param item
             * @param response
             * @param status
             * @param headers
             */
            vm.uploader.onErrorItem = function(item, response, status, headers) {
                var file = vm.files[item.modelIndex];

                // Mark upload stopped
                file.uploader.active = false;

                // Handle error code
                // TODO: HC-175: Make0 this use validation messages ey?
                if (status == 404) {
                    file.error = "Application error: Route does not exist.";
                } else if (status >= 400 && status < 500) {
                    file.error = "User error";
                } else {
                    file.error = "Server error. Please try again.";
                }
            };

            /**
             * Add a "slot" to the Files array, so a file can be uploaded in this location.
             */
            vm.addFile = function() {
                vm.files.push(new AsyncFile());
            };

            /**
             * Delete the specified file item slot
             * @param fileObject
             */
            vm.deleteFile = function(fileObject) {

                // Find the object in the array
                var index = vm.files.indexOf(fileObject);
                if (index === -1) {
                    throw "Unable to locate file object in files array";
                }

                // Remove the item
                vm.files.splice(index, 1);
                // remove the display url after remove file
                $scope.getDisplayUrl({
                    'fieldName': $scope.fieldName,
                    'fileIndex': index,
                    'fileDisplayUrl': null
                });
            };

            /**
             * Create a download URL using the token
             *
             * @param {AsyncFile} asyncFileObject
             * @return string
             */
            vm.getDownloadUrl = function(asyncFileObject)
            {
                return inputAsyncFileService.getDownloadUrl(asyncFileObject);
            };

            /**
             * Returns true if the uploader should be displayed
             * @returns {boolean}
             */
            vm.isUploaderVisible = function() {

                if (!vm.config.disabled) {
                    // Disabled field never shows uploader
                    return false;
                } else if (vm.config.multiple && vm.files.length < vm.config.multiple_limit) {
                    // Multiple, below limit, shows uploader
                    return true;
                } else if (!vm.config.multiple && vm.files.length < 1) {
                    // Non-multi always shows uploader if no files
                    return true;
                }

                return false;
            };

            /**
             * Is this model able to be deleted?
             *
             * @param {AsyncFile} model
             * @returns {boolean}
             */
            vm.isDeleteVisible = function(model) {
                // You can remove a file so long as its valid
                return model.isValid();
            };

            /**
             * Get the help tooltip text for the upload field
             */
            vm.getUploadHelp = function() {
                var mime =  vm.config.mime_types_human.join("\n");
                var size = vm.config.file_size_human;
                return mime+"<br/>"+size;
            };

            // Retrieve the ngModel controller
            var ngModel = $element.controller('ngModel');

            /**
             * On: Internal model is changed by child directive
             * Push the change, serialized, to the outer model.
             */
            $scope.$watch(function() {
                return vm.files;
            }, function(value) {
                ensureFilesMulti();
                ngModel.$setViewValue(inputAsyncFileService.serializeAsyncFiles(value));
            }, true);

            /**
             * On: variable pushed to us from ngModel
             * Deserialize and push the values to the inner model.
             */
            ngModel.$render = function() {
                if(!ngModel.$viewValue) {
                    return "";
                }
                else if ($.isPlainObject(ngModel.$viewValue)) {
                    if($.isEmptyObject(ngModel.$viewValue)) {
                        return "";
                    }
                }

                var viewValue = ngModel.$viewValue;

                vm.files = inputAsyncFileService.deserializeAsyncFiles(viewValue);
                ensureFilesMulti();
            };

            /**
             * Ensure the Files array is kept at appropriate lengths
             */
            var ensureFilesMulti = function() {
                if (vm.config.multiple) {

                    // Ensure multiple_limit is enforced
                    if (vm.files.length > vm.config.multiple_limit) {
                        vm.files = vm.files.slice(0, vm.config.multiple_limit);
                    }
                } else {
                    // Require at least one field to be visible
                    if (vm.files.length < 1) {
                        vm.addFile();
                    }

                    // Ensure no more than one field is visible
                    vm.files = vm.files.slice(0, 1);
                }
            }

        }
    }

})(
    /** @type {angular} */
    angular
);