'use strict';

angular.module('elmo.factory.debounce', []).factory('debounce', ['$timeout','$q', function($timeout, $q) {
    return function debounce(func, wait, immediate) {
        var timeout;
        var deferred = $q.defer();

        return function() {
            var context = this, args = arguments;

            // if immediate is set to true or if waiting time is set to zero, call function
            if (immediate || !wait) {
                deferred.resolve(func.apply(context, args));
                deferred = $q.defer();
            } else {
                if (timeout) $timeout.cancel(timeout);

                timeout = $timeout(function() {
                    timeout = null;
                    deferred.resolve(func.apply(context, args));
                    deferred = $q.defer();
                }, wait);
            }

            return deferred.promise;
        };
    };
}]);
