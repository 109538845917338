'use strict';

(function(angular) {

    /**
     * Entity Choice Input Service
     *
     * Manages communication with the Entity Choice API
     */
    angular
        .module('elmo.directives.input-entity-choice.service', ['elmo.service.router'])
        .service('inputEntityChoiceService', InputEntityChoiceService);

    /**
     * Handles Entity Choice interaction between front-end and the server
     *
     * @typedef InputEntityChoiceService
     * @param {angular.$q} $q
     * @param {angular.http} $http
     * @param {router} router
     */
    function InputEntityChoiceService($q, $http, router) {

        /**
         * Search the EntityChoice endpoint using the given Token and search parameters.
         *
         * @param {InputEntityChoiceConfig} config
         * @param {string} text
         * @param {int} page
         * @return {ng.promise}
         */
        this.searchEntities = function(config, text, page) {
            var deferred = $q.defer();

            // Request
            var promise = $http({
                url: router.generate('elmo_app_apiv0_entity_choice_list'),
                method: 'GET',
                params: {
                    token: config.token,
                    page: page,
                    text: text
                }
            });

            // Mapped outcome
            promise.then(
                // Success
                function(response) {
                    deferred.resolve(response.data);
                },
                // Error
                function(response) {
                    deferred.reject();
                }
            );

            return deferred.promise;
        }

    }

})(
    /** @type {angular} */
    angular
);